import {
  log,
  viewport,
  debounce
} from './_utils.js';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// const navMain = document.getElementById( 'offcanvas' )
// const navScrollTop = document.getElementById( 'scrollTop' )

const scroller = () => {
  let anchorlinks = document.querySelectorAll('a[href*="#"]')
  
  for (let item of anchorlinks) { // relitere 
    item.addEventListener('click', (e) => {
      
      const href = item.getAttribute('href')
      const hash = href.split('#').pop();
      
      /* scroll to target */
      let target = document.getElementById(hash)
      if ( !target ) {
        return
      }
      target.scrollIntoView({
        behavior: 'smooth', // smooth , auto
        block: 'start'
      })
      e.preventDefault();
      
      
      

      /* close offCanvas */
      // document.querySelector('.st-container').classList.remove('st-menu-open');
      // document.querySelector('.burger').classList.remove('close');

      

    })
  }
}
scroller()