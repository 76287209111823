
import {
  log,
  viewport,
  debounce
} from './_utils.js';


export default (el) => {
  return init(el);
};



const results = document.querySelectorAll('.figur_list > [data-has]')
let firstLoad = true;

function hide(element) {
    element.classList.add('visually_hidden');
    setTimeout(() => {
      element.classList.add('display_hidden');
    }, 100);
}

function show(element, filter) {
  if (element.dataset.has === filter || filter === false) {

      element.classList.remove('display_hidden');
      setTimeout(() => {
        element.classList.remove('visually_hidden');
      }, 100);

  }
  

  // if (!visible) {
  //   element.classList.add('visually_hidden', 'display_hidden');
  // }
}


const init = (el) => {
  const filter = el?.dataset?.filter ?? false;
  // console.log(filter);
  if (firstLoad) {
    firstLoad = false;
    return;
  }

  results.forEach(element => {
    hide(element)
    setTimeout(() => {
      show(element, filter)
    }, 100);
  })
  
}
